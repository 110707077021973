import React, { FC, useContext, useState } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { useGetFormsList } from 'src/shared/lib/hooks/useGetFormsList';
import { ProjectType } from 'src/shared/store/projects/types';

import { FormsList } from '../FormsList';

interface ProjectListItemProps {
  project: ProjectType;
  handleOpenFormModal: () => void;
}

export const ProjectListItem: FC<ProjectListItemProps> = ({ project, handleOpenFormModal }) => {
  const { id, project_name } = project;
  const { selectedProject } = useContext(ProjectsContext);
  const { handleSelectProject } = useGetFormsList();

  const [isOpen, setIsOpen] = useState(false);

  const isSelected = id === selectedProject?.id && isOpen;

  return (
    <div className="mb-1">
      <div
        className={cn(
          {
            'border-violet-800 bg-violet-700': id === selectedProject?.id,
            'hover:border-violet-800 hover:bg-violet-700': id !== selectedProject?.id,
          },
          'flex min-h-10 w-full cursor-pointer items-center px-2.5 transition-all',
          'rounded-md border-2 border-transparent',
        )}
        onClick={async () => {
          setIsOpen(prev => !prev);
          handleSelectProject(project);
        }}
      >
        <div className="font-gothampro-400 w-full truncate text-white">{project_name}</div>
        <button className="ml-auto h-5 w-5">
          <ArrowSmallIcon
            className={cn(
              { 'rotate-90': isSelected, '-rotate-90': !isSelected },
              'h-3 w-3 stroke-white',
            )}
          />
        </button>
      </div>

      <FormsList isSelected={isSelected} handleOpenFormModal={handleOpenFormModal} />
    </div>
  );
};
