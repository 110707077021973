import { DragEvent, useState } from 'react';

interface UseResizeProps {
  id: string;
  callBack?: (width: number) => void;
}

export const useResize = ({ id, callBack }: UseResizeProps) => {
  const [initialPos, setInitialPos] = useState<number>(0);
  const [initialSize, setInitialSize] = useState<number>(0);

  const onDragStart = (e: DragEvent<HTMLDivElement>) => {
    const resizable = document.getElementById(id);

    if (!resizable) return;

    setInitialPos(e.clientX);
    setInitialSize(resizable.offsetWidth);
  };

  const onDrag = (e: DragEvent<HTMLDivElement>) => {
    const resizable = document.getElementById(id);

    if (!resizable || !e.clientX) return;

    const width = initialSize + (e.clientX - initialPos);

    if (width > 600) {
      return;
    }

    callBack && callBack(width);
    resizable.style.width = `${width}px`;
  };

  return { onDragStart, onDrag };
};
