import React, { FC, useContext } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { useGetForm } from 'src/shared/lib/hooks/useGetForm';
import { UserContext } from 'src/shared/store/user';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface FormsListProps {
  isSelected: boolean;
  handleOpenFormModal: () => void;
}

export const FormsList: FC<FormsListProps> = ({ isSelected, handleOpenFormModal }) => {
  const { forms, isFormsLoading, selectedForm } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);

  const { handleSelectForm } = useGetForm();

  return (
    <div className="mb-4">
      {isSelected && isFormsLoading && (
        <div className="flex h-28 w-full items-center justify-center">
          <Loader classNames="w-10 h-10" />
        </div>
      )}

      {isSelected && !isFormsLoading && (
        <div className="w-full">
          {!forms?.length && (
            <div
              className={'flex h-14 min-h-14 w-full items-center justify-center bg-blue-rgba-100'}
            >
              <div className="font-gothampro-400 tex-sm text-white">Формы не найдены</div>
            </div>
          )}

          {forms.map(form => (
            <div
              key={`form-${form.id}`}
              className={cn(
                {
                  'opacity-100': form.id === selectedForm?.id,
                  'opacity-60 hover:opacity-100': form.id !== selectedForm?.id,
                },
                'ml-5 flex h-10 min-h-10 w-64 cursor-pointer items-center',
              )}
              onClick={() => handleSelectForm(form)}
            >
              <div className="font-gothampro-400 tex-sm truncate text-white">{form.form_name}</div>
            </div>
          ))}

          {!user?.isOperator && (
            <div className="ml-5 pr-1">
              <Button variant="violetDark" onClick={handleOpenFormModal}>
                Добавить форму
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
