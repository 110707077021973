import { ITableContext, TablePartsType } from './types';

export const initNoticeData = {
  delete: 0,
  update: 0,
  create: 0,
};

export const initTableParts: TablePartsType = {
  loadedPages: [],
  totalPages: 1,
  totalRows: 1,
};

export const rowsLimit = 50;

export const tableContextDefaultState: ITableContext = {
  totalRows: 0,
  tableData: [],
  isLoading: false,
  isEdit: false,
  setIsEdit: () => {},
  setTableData: () => {},
  resetTableData: () => {},
  addEmptyRows: () => {},
  setIsLoading: () => {},
  saveRows: () => {},
  setSort: () => {},
  setSearch: () => {},
  getBottomContent: () => {},
  getTopContent: () => {},
  addEmptyTopRows: () => {},
};
