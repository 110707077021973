import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import TableService from 'src/shared/api/table/TableService';
import TokenService from 'src/shared/api/token/TokenService';
import { TABLE_SOCKET_URL } from 'src/shared/config';
import { setError, sleep } from 'src/shared/lib/utils';
import {
  initTableParts,
  rowsLimit,
  tableContextDefaultState,
} from 'src/shared/store/table/constants';
import {
  createEmptyRow,
  createEmptyRows,
  createRowsBatchData,
  loadTopScroll,
  parseRowsBatchDataRes,
  scrollTableToBottom,
} from 'src/shared/store/table/helpers';
import { ITableContext, ListItem, SortItem, TablePartsType } from 'src/shared/store/table/types';
import { UserContext } from 'src/shared/store/user';

export const TableContext = createContext<ITableContext>(tableContextDefaultState);

interface ITableProviderProps {
  children: ReactNode;
}

export const TableProvider: FC<ITableProviderProps> = ({ children }) => {
  const ref = useRef<TablePartsType>(initTableParts);
  const addRowsRef = useRef<number>(0);
  const socket = useRef<WebSocket | null>(null);
  const {
    tableData: _tableData,
    isLoading: _isLoading,
    isEdit: _isEdit,
  } = tableContextDefaultState;
  const { tableSettings } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);

  const [tableData, setTableData] = useState<ListItem[]>(_tableData);
  const [initialData, setInitialData] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(_isLoading);
  const [sort, setSort] = useState<SortItem | null>();
  const [search, setSearch] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(_isEdit);

  useEffect(() => {
    if (!tableSettings?.formStructure?.length) {
      return;
    }

    if (user?.isDeveloper) {
      const arr: ListItem[] = Array.from({ length: 100 }).map((_, i) => {
        const obj: ListItem = {};

        tableSettings.formStructure.forEach(({ columnLabel, columnType, columnName }) => {
          if (columnType === 'text') obj[columnName] = `${columnLabel} - ${i + 1}`;
          if (columnType === 'number') obj[columnName] = i + 1;
          if (columnType === 'floatNumber') obj[columnName] = i + 1.1;
          if (columnType === 'date') obj[columnName] = new Date().getTime();
        });

        return obj;
      });
      setTableData(arr);
      return;
    }

    setTableData(_tableData);
    setInitialData('');
    setSort(null);
    setSearch('');
    setIsEdit(false);
    socket.current = new WebSocket(TABLE_SOCKET_URL);
    socket.current.onopen = () => getData({ page: 1 });

    socket.current.onmessage = async ({ data }) => {
      const tableInfo = JSON.parse(data);

      if (!data || tableInfo?.data === null) {
        setTableData([createEmptyRow(tableSettings)]);
        setInitialData('');
        ref.current = {
          loadedPages: [],
          totalPages: 0,
          totalRows: 0,
        };
        return;
      }

      const { currentPage, totalPages, totalRows } = tableInfo;
      const props = { totalPages, totalRows };
      try {
        const list = tableInfo?.data.map((v: ListItem) => ({
          ...v,
          actions: { isEdit: false, isRemoved: false },
        }));
        const loadedPages = ref.current.loadedPages;

        if (loadedPages?.[0] > currentPage) {
          ref.current = { loadedPages: [currentPage, ...loadedPages], ...props };
          setTableData(prev => [...list, ...prev]);
          setInitialData(prev => JSON.stringify([...tableInfo.data, ...JSON.parse(prev)]));
          setIsLoading(false);

          await sleep(100);
          loadTopScroll();
          return;
        }

        if (loadedPages?.[loadedPages.length - 1] < currentPage) {
          const newRows = addRowsRef.current;
          ref.current = { loadedPages: [...loadedPages, currentPage], ...props };
          setTableData(prev => [
            ...prev,
            ...list,
            ...(newRows ? createEmptyRows(newRows, tableSettings) : []),
          ]);
          setInitialData(prev => JSON.stringify([...JSON.parse(prev), ...tableInfo.data]));
          setIsLoading(false);

          if (newRows) {
            await sleep(100);
            scrollTableToBottom();
          }

          addRowsRef.current = 0;
          return;
        }

        ref.current = { loadedPages: [currentPage], ...props };
        setTableData(list);
        setInitialData(JSON.stringify(tableInfo.data));
        setIsLoading(false);
      } catch (e) {
        setError('Ошибка обработки данных');
        setIsLoading(false);
      }
    };
  }, [tableSettings, user]);

  const getData = ({
    page,
    newSort,
    newSearch,
  }: {
    page?: number;
    newSort?: SortItem;
    newSearch?: string;
  }) => {
    const access = TokenService.getAccessToken();
    const ws = socket.current;

    if (!access || !ws || ws.readyState !== WebSocket.OPEN) {
      return;
    }

    const currentSort = newSort || sort;
    const currentSearch = typeof newSearch === 'string' ? newSearch : search;
    const sortString = currentSort
      ? `&sortField=${currentSort.name}&sortDirection=${currentSort.sort}`
      : '';
    const searchString = `&search=${currentSearch}`;

    ws.send(
      `page=${page}&limit=${rowsLimit}&tableID=${tableSettings?.id}&tokenString=${access}${sortString}${searchString}`,
    );
  };

  const getBottomContent = () => {
    const { totalPages, loadedPages } = ref.current;
    const lastPage = loadedPages[loadedPages.length - 1];

    if (lastPage < totalPages) {
      getData({ page: lastPage + 1 });
    }
  };

  const getTopContent = () => {
    const { loadedPages } = ref.current;
    const firstPage = loadedPages[0];

    if (firstPage !== 1) {
      getData({ page: firstPage - 1 });
    }
  };

  const resetTableData = () => {
    if (!initialData) {
      setTableData([createEmptyRow(tableSettings)]);
      return;
    }

    const list = JSON.parse(initialData).map((v: ListItem) => ({
      ...v,
      actions: { isEdit: false, isRemoved: false },
    }));
    setTableData(list);
  };

  const addEmptyRows = (count: number) => {
    const { loadedPages, totalPages } = ref.current;

    if (loadedPages.includes(totalPages) || !totalPages) {
      const newRows = Array.from({ length: count }).map(() => createEmptyRow(tableSettings));
      setTableData(prev => [...prev, ...newRows]);
      scrollTableToBottom();

      return;
    }

    const page = totalPages;
    setIsLoading(true);
    addRowsRef.current = count;
    getData({ page: page - 1 });
    ref.current = initTableParts;
    getData({ page });
  };

  const addEmptyTopRows = () => {
    const newRow = createEmptyRow(tableSettings);
    setTableData(prev => [newRow, ...prev]);
  };

  const handleSetSort = (newSort: SortItem) => {
    setSort(newSort);
    getData({ page: 1, newSort });
  };

  const handleSetSearch = (newSearch: string) => {
    setSearch(newSearch);
    getData({ page: 1, newSearch });
  };

  const saveRows = async () => {
    if (!tableSettings?.id) {
      return;
    }

    setIsLoading(true);

    const clearlyData = tableData.map(el => {
      const temp = { ...el };
      delete temp?.['actions'];
      delete temp?.['isDateOpen'];
      delete temp?.['isOpen'];

      return temp;
    });

    if (JSON.stringify(clearlyData) === initialData) {
      setIsLoading(false);
      return;
    }

    const params = createRowsBatchData({ initialData, clearlyData });

    try {
      const { data } = await TableService.batchRows({ data: params, table_id: tableSettings.id });

      parseRowsBatchDataRes({ data, setTableData, setInitialData, initialData });
    } catch (e) {
      setError('Ошбика сохранения данных');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TableContext.Provider
      value={{
        isLoading,
        tableData,
        isEdit,
        setIsEdit,
        setIsLoading,
        setTableData,
        resetTableData,
        addEmptyRows,
        addEmptyTopRows,
        saveRows,
        setSort: handleSetSort,
        setSearch: handleSetSearch,
        getBottomContent,
        getTopContent,
        totalRows: ref?.current?.totalRows || 0,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
