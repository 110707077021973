import { FC, useContext, useRef } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as InfoCircleIcon } from 'src/shared/assets/icons/kit/info-circle.svg';
import { useClickOutside } from 'src/shared/lib/hooks/useClickOutside';

interface TableInfoProps {
  onClose: () => void;
}

export const TableInfo: FC<TableInfoProps> = ({ onClose }) => {
  const { tableSettings } = useContext(ProjectsContext);
  const { totalRows } = useContext(TableContext);

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, onClose);

  const getLine = (label: string, value?: string) =>
    value ? (
      <div className="mb-3 flex">
        <div className="font-gothampro-400 w-1/3 text-sm text-white opacity-50">{label}</div>
        <div className="font-gothampro-400 text-sm text-white">{value}</div>
      </div>
    ) : (
      <></>
    );

  return (
    <div className="bg-violet-1100 w-full rounded-xl p-5" ref={ref}>
      <div className="mb-10 flex w-full items-center">
        <InfoCircleIcon className="mr-3 h-6 w-6 fill-blue-300" />
        <div className="font-gothampro-500 text-lg text-white">Сведения о форме</div>
        <CloseIcon className="ml-auto h-5 w-5 cursor-pointer stroke-grey-300" onClick={onClose} />
      </div>

      {getLine('Имя формы:', tableSettings?.formName)}
      {getLine('Количество строк:', totalRows.toString())}
      {getLine('Описание формы:', tableSettings?.formDescription)}
      {getLine('СУБД:', tableSettings?.connection)}
    </div>
  );
};
