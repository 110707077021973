import { FC, useMemo } from 'react';
import cn from 'classnames';

export type DatePickerCellProps = {
  dayNumber?: number;
  weekDay?: string;
  isMarkedDate?: boolean;
  onClick?: () => void;
  classNames?: string;
};

export const DatePickerCell: FC<DatePickerCellProps> = ({
  dayNumber,
  weekDay,
  isMarkedDate,
  onClick,
  classNames,
}) => {
  const styles = useMemo(
    () =>
      cn(
        {
          'hover:bg-grey-1100 hover:border-violet-500 cursor-pointer':
            !Boolean(isMarkedDate) && !Boolean(weekDay),
          'bg-violet-1300 text-white border-violet-1300': Boolean(isMarkedDate),
          uppercase: Boolean(weekDay),
        },
        'rounded-xl w-8 h-8 min-w-[2rem] flex items-center justify-center border border-transparent',
        'text-xs font-gothampro-400',
        classNames,
      ),
    [weekDay, classNames, isMarkedDate],
  );

  return (
    <div className={styles} {...(onClick && { onClick: onClick })}>
      {dayNumber || weekDay || ''}
    </div>
  );
};
