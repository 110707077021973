import { FC, useState } from 'react';
import { Oferta } from 'src/shared/ui';
import { ProjectTablePage } from 'src/widgets/ProjectTable';
import { Sidebar } from 'src/widgets/Sidebar';

export const TablePage: FC = () => {
  const [fullWidth, setFullWidth] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(320);

  return (
    <div className="relative flex w-full">
      {!fullWidth && <Sidebar setWidth={setWidth} />}

      <div className="flex h-full w-full flex-col">
        <ProjectTablePage fullWidth={fullWidth} width={width} setFullWidth={setFullWidth} />

        <Oferta classNames="p-2.5" />
      </div>
    </div>
  );
};
