import { FC, MouseEvent, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { emptyError } from 'src/shared/const';
import { isEmptyObject } from 'src/shared/lib/utils';
import { Button, Input } from 'src/shared/ui';

import { CreateProjectFormType } from '../types';

interface CreateProjectFormProps {
  onClose: () => void;
}

export const CreateProjectForm: FC<CreateProjectFormProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateProjectFormType>();

  const { projects, setProjects } = useContext(ProjectsContext);
  const disabled = !isEmptyObject(errors) || isSubmitting;

  const onSubmit: SubmitHandler<CreateProjectFormType> = async params => {
    try {
      const { data } = await ProjectsService.createProject({
        ...params,
        createdBy: 'admin',
        isVisible: true,
      });
      if (!data) {
        return;
      }

      const newProject = {
        id: data.ID,
        project_description: data.ProjectDescription,
        project_name: data.ProjectName,
      };

      setProjects([...projects, newProject]);
    } catch (e) {
    } finally {
      onClose();
    }
  };

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 flex w-full">
        <Input
          {...register('projectName', { required: emptyError })}
          error={errors.projectName?.message}
          placeholder="Введите название"
          label="Название"
          containerClassNames="w-64"
        />
        <Input
          {...register('projectDescription', { required: emptyError })}
          error={errors.projectDescription?.message}
          placeholder="Введите описание"
          label="Описание"
          containerClassNames="w-64 ml-2.5"
        />
      </div>

      <div className="flex justify-center">
        <Button variant="green" disabled={disabled} classNames="w-48 mr-2.5" type="submit">
          Сохранить
        </Button>
        <Button variant="grey" classNames="w-48" onClick={handleCancel}>
          Отменить
        </Button>
      </div>
    </form>
  );
};
