import React, { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';
import { ReactComponent as UserIcon } from 'src/shared/assets/icons/kit/user-outline.svg';
import { useGetForm } from 'src/shared/lib/hooks/useGetForm';
import { debounce } from 'src/shared/lib/utils';
import { UserContext } from 'src/shared/store/user';
import { Button } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface FormsListProps {
  handleOpenFormModal: () => void;
}

export const FormsList: FC<FormsListProps> = ({ handleOpenFormModal }) => {
  const { forms, isFormsLoading, selectedForm, selectedProject } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);
  const [search, setSearch] = useState<string>('');

  const { handleSelectForm } = useGetForm();

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 500);

  const filteredForms = useMemo(() => {
    if (!search) {
      return forms;
    }

    return forms.filter(({ form_name }) => form_name.toLowerCase().includes(search.toLowerCase()));
  }, [forms, search]);

  return (
    <div className="flex w-56 flex-shrink-0 flex-col bg-white">
      <div className="flex h-16 w-full flex-shrink-0 items-center justify-center px-2.5">
        <div className="font-gothampro-400 mr-1 truncate text-extra-xs">
          {user?.email || 'Login'}
        </div>
        <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-violet-1700">
          <UserIcon className="-mt-0.5 h-4 w-4 fill-violet-1600" />
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        {selectedProject && isFormsLoading && (
          <div className="flex h-28 w-full items-center justify-center">
            <Loader classNames="w-10 h-10" />
          </div>
        )}

        {selectedProject && !isFormsLoading && (
          <>
            <div className="flex h-14 w-full items-center border-b border-t border-grey-1000 px-2.5">
              <input
                className="font-gothampro-400 w-10/12 text-xs"
                placeholder="Поиск"
                onChange={handleSearch}
              />
              <LoupeIcon className="ml-auto h-2.5 w-2.5 flex-shrink-0 fill-grey-800" />
            </div>

            {!forms?.length && (
              <div className="flex h-14 min-h-14 w-full items-center justify-center bg-blue-rgba-100">
                <div className="font-gothampro-400 tex-sm text-white">Формы не найдены</div>
              </div>
            )}

            <div className="overflow-auto" style={{ height: 'calc(100dvh - 180px)' }}>
              {filteredForms.map(form => (
                <div
                  key={`form-${form.id}`}
                  className={cn(
                    {
                      'bg-grey-1000 opacity-100': form.id === selectedForm?.id,
                      'opacity-60 hover:bg-grey-1000 hover:opacity-100':
                        form.id !== selectedForm?.id,
                    },
                    'flex h-14 cursor-pointer items-center border-b border-grey-1000 px-2.5',
                  )}
                  onClick={() => handleSelectForm(form)}
                >
                  <div className="font-gothampro-400 truncate text-xs">{form.form_name}</div>
                </div>
              ))}
            </div>

            {!user?.isOperator && (
              <div className="mb-4 mt-auto px-2">
                <Button variant="violetOutline" size="small" onClick={handleOpenFormModal}>
                  Добавить
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
