import { useContext, useEffect } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';

export const useCheckAddRow = () => {
  const { addEmptyRows, setTableData } = useContext(TableContext);
  const { setSelectedForm, setTableSettings } = useContext(ProjectsContext);

  const checkAddRow = e => {
    if (e.key === 'Escape') {
      setSelectedForm(null);
      setTableSettings(null);
      setTableData([]);
    }

    if ((e.ctrlKey && e.keyCode === 13) || e.keyCode === 45) {
      addEmptyRows(1);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', checkAddRow);

    return () => {
      document.removeEventListener('keydown', checkAddRow);
    };
  }, []);
};
