import { Dispatch, FC, SetStateAction } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/kit/arrow.svg';
import { ReactComponent as CheckIcon } from 'src/shared/assets/icons/kit/check.svg';
import { useLogout } from 'src/shared/lib/hooks/useLogout';

interface HeadItemsProps {
  fullWidth: boolean;
  setFullWidth: Dispatch<SetStateAction<boolean>>;
}

export const HeadItems: FC<HeadItemsProps> = ({ fullWidth, setFullWidth }) => {
  const { handleLogout } = useLogout();

  return (
    <div className="absolute right-2.5 top-5 z-10 flex items-center">
      <div className="flex cursor-pointer items-center" onClick={() => setFullWidth(prev => !prev)}>
        <div
          className={cn(
            { 'bg-violet-1500': fullWidth, 'bg-white': !fullWidth },
            '-mt-1 mr-2 flex h-6 w-6 items-center justify-center rounded border border-violet-1500',
          )}
        >
          {fullWidth && <CheckIcon className="h-4 w-4 stroke-white" />}
        </div>
        <div className="font-gothampro-400 text-sm">
          {fullWidth ? 'Выйти из полноэкранного режима' : 'Полноэкранный режим'}
        </div>
      </div>

      <button className="group ml-10 flex items-center" onClick={handleLogout}>
        <div className="font-gothampro-500 mr-2.5 text-grey-400 transition-all group-hover:text-grey-300">
          Выход
        </div>
        <ArrowIcon className="h-4 w-4 fill-grey-400 transition-all group-hover:fill-grey-300" />
      </button>
    </div>
  );
};
