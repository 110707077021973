import { ReactNode } from 'react';
import {
  CellStyle,
  CellTemplate,
  Compatible,
  getCellProperty,
  HeaderCell,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid';
import cn from 'classnames';
import { ReactComponent as SortIcon } from 'src/shared/assets/icons/kit/sort.svg';
import { sortEnum } from 'src/shared/const';
import { SortType } from 'src/shared/types';
import { ToolTip } from 'src/shared/ui';

export interface MyHeaderCell extends HeaderCell {
  enableSort?: boolean;
  enableFilter?: boolean;
  sort?: SortType;
  label?: string;
  needRemove?: boolean;
}

export class HeaderCellTemplate implements CellTemplate<MyHeaderCell> {
  getCompatibleCell(uncertainCell: Uncertain<MyHeaderCell>): Compatible<MyHeaderCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  update(
    cell: Compatible<MyHeaderCell>,
    cellToMerge: UncertainCompatible<MyHeaderCell>,
  ): Compatible<MyHeaderCell> {
    return this.getCompatibleCell({
      ...cell,
      sort: cellToMerge.sort,
      ...(cellToMerge.needRemove && { needRemove: cellToMerge.needRemove }),
    });
  }

  render(
    cell: Compatible<MyHeaderCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<MyHeaderCell>, commit: boolean) => void,
  ): ReactNode {
    const { enableSort, sort, text, label } = cell;

    const handleChangeSort = () => {
      onCellChanged(
        this.getCompatibleCell({
          ...cell,
          sort: sort === sortEnum.ASC ? sortEnum.DESC : sortEnum.ASC,
        }),
        true,
      );
    };

    const handleRemoveColumn = () => {
      onCellChanged(
        this.getCompatibleCell({
          ...cell,
          needRemove: true,
        }),
        true,
      );
    };

    return (
      <ToolTip label={label} placement="top">
        <div className="flex w-full items-center">
          {enableSort && (
            <button
              onClick={handleChangeSort}
              className="mr-2 flex h-4 w-4 flex-shrink-0 items-center justify-center"
            >
              <SortIcon
                className={cn(
                  { 'rotate-180': sort === sortEnum.ASC },
                  'h-4 w-4 fill-grey-500 hover:fill-pink-300',
                )}
              />
            </button>
          )}
          <div className="truncate">{text}</div>
          {/*{enableSort && (*/}
          {/*  <button*/}
          {/*    onClick={handleRemoveColumn}*/}
          {/*    className={*/}
          {/*      'flex h-8 w-8 flex-shrink-0 items-center justify-center border-grey-500 ' +*/}
          {/*      'group ml-auto rounded border hover:border-pink-300'*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <TrashIcon className="h-4 w-4 stroke-grey-500 group-hover:stroke-pink-300" />*/}
          {/*  </button>*/}
          {/*)}*/}
        </div>
      </ToolTip>
    );
  }

  isFocusable = (cell: Compatible<MyHeaderCell>): boolean => false;

  getClassName(cell: Compatible<MyHeaderCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : '';
  }

  getStyle = (cell: Compatible<MyHeaderCell>): CellStyle => ({
    background: 'rgba(128, 128, 128, 0.1)',
  });
}
