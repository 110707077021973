import { AxiosResponse } from 'axios';
import { ApiAuth } from 'src/shared/api/ApiAuth';
import { LoginType, ResetType, TokenType } from 'src/shared/types';

import { GetUserResponse, LoginResponse, LogoutResponse, RefreshParams } from './types';

const login = (params: LoginType) =>
  ApiAuth.post<string, Promise<AxiosResponse<LoginResponse>>, LoginType>(`/login`, params);

const reset = (params: ResetType) =>
  ApiAuth.post<string, Promise<AxiosResponse<LoginResponse>>, ResetType>(`/reset-password`, params);

const logout = () => ApiAuth.post<string, Promise<AxiosResponse<LogoutResponse>>, void>(`/logout`);

const refresh = (params: RefreshParams) =>
  ApiAuth.post<string, Promise<AxiosResponse<TokenType>>, RefreshParams>(`/refresh`, params);

const getUser = () => ApiAuth.get<void, Promise<AxiosResponse<GetUserResponse>>>(`/user`);

const AuthService = { login, logout, refresh, getUser, reset };

export default AuthService;
