export const headerHeight = 52;
export const cellHeight = 44;

export const columnNames = {
  text: 'Текст',
  number: 'Целое число',
  floatNumber: 'Дробное число',
  date: 'Дата',
  reference: 'Справочник',
  dropdown: 'Справочник',
};
