import { FC, useContext } from 'react';
import { TableContext } from 'src/app/providers/TableProvider';
import { Button } from 'src/shared/ui';

export const TableButtons: FC = () => {
  const { resetTableData, saveRows, isLoading, setIsEdit } = useContext(TableContext);

  const onCancel = () => {
    resetTableData();
    setIsEdit(false);
  };

  return (
    <div className="ml-auto flex items-center">
      <Button classNames="w-56 mr-3" variant="violet" onClick={saveRows} disabled={isLoading}>
        Сохранить
      </Button>

      <Button classNames="w-56" variant="grey" onClick={onCancel} disabled={isLoading}>
        Отменить
      </Button>
    </div>
  );
};
