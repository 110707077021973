import { useContext, useEffect } from 'react';
import { TableContext } from 'src/app/providers/TableProvider';
import { debounce } from 'src/shared/lib/utils';

export const useInfinityScroll = () => {
  const { getBottomContent, getTopContent } = useContext(TableContext);

  const checkPosition = debounce((container: HTMLDivElement) => {
    const { scrollHeight, clientHeight, scrollTop } = container;

    const position = scrollTop + clientHeight;
    const threshold = scrollHeight - clientHeight;

    if (position >= threshold) {
      getBottomContent();
    }

    if (position <= threshold) {
      getTopContent();
    }
  }, 400);

  useEffect(() => {
    const tableContainer = document.querySelector('#table-container') as HTMLDivElement;

    if (!tableContainer) {
      return;
    }

    tableContainer.addEventListener('scroll', () => checkPosition(tableContainer));

    return () => {
      tableContainer.removeEventListener('scroll', () => checkPosition(tableContainer));
    };
  }, []);
};
