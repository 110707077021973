import { useContext } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import AuthService from 'src/shared/api/auth/AuthService';
import TokenService from 'src/shared/api/token/TokenService';
import { projectsContextDefaultState } from 'src/shared/store/projects/constants';
import { tableContextDefaultState } from 'src/shared/store/table/constants';

export const useLogout = () => {
  const { setForms, setSelectedForm, setSelectedProject, setTableSettings, setProjects } =
    useContext(ProjectsContext);
  const { setUser } = useContext(UserContext);
  const { setTableData } = useContext(TableContext);

  const handleLogout = async () => {
    try {
      await AuthService.logout();
    } catch (e) {
    } finally {
      const { forms, selectedForm, selectedProject, tableSettings, projects } =
        projectsContextDefaultState;
      setForms(forms);
      setSelectedForm(selectedForm);
      setSelectedProject(selectedProject);
      setTableSettings(tableSettings);
      setProjects(projects);

      setTableData(tableContextDefaultState.tableData);

      TokenService.removeTokens();
      setUser(null);
    }
  };

  return { handleLogout };
};
