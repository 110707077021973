import React, { FC, useContext } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { ReactComponent as FolderIcon } from 'src/shared/assets/icons/kit/folder.svg';
import { ReactComponent as FolderOpenIcon } from 'src/shared/assets/icons/kit/folder-open.svg';
import { useGetFormsList } from 'src/shared/lib/hooks/useGetFormsList';
import { ProjectType } from 'src/shared/store/projects/types';

interface ProjectListItemProps {
  project: ProjectType;
}

export const ProjectListItem: FC<ProjectListItemProps> = ({ project }) => {
  const { selectedProject } = useContext(ProjectsContext);

  const isSelected = project.id === selectedProject?.id;

  const { handleSelectProject } = useGetFormsList();

  return (
    <div
      className={cn(
        {
          'bg-violet-1400': isSelected,
          'hover:bg-violet-1400': !isSelected,
        },
        'group flex h-14 w-full cursor-pointer flex-col items-center justify-center px-1 transition-all',
      )}
      onClick={() => handleSelectProject(project)}
    >
      {isSelected ? (
        <FolderOpenIcon className="h-5 w-4 fill-white" />
      ) : (
        <FolderIcon className="h-5 w-5 fill-violet-1800" />
      )}
      <div
        className={cn(
          { 'text-grey-500 group-hover:text-white': !isSelected, 'text-white': isSelected },
          'font-gothampro-400 mt-1 w-full truncate text-center text-extra-xs',
        )}
      >
        {project.project_name}
      </div>
    </div>
  );
};
