import { FC } from 'react';
import { Oferta } from 'src/shared/ui';
import { ResetForm } from 'src/widgets/ResetForm';

export const ResetPage: FC = () => {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex h-full w-full items-center justify-center">
        <ResetForm />
      </div>
      <Oferta classNames="mt-auto px-5 pb-2" />
    </div>
  );
};
